import React from "react";
import { Col, Row, Tooltip, Overlay } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FaHubspot } from "react-icons/fa";
import { FaSalesforce } from "react-icons/fa";
import { SiZendesk } from "react-icons/si";
import { SiTwilio } from "react-icons/si";
import { SiDynamics365 } from "react-icons/si";
import GenesysLogo from "../../Assets/genesyslogo_branco.png";
import PipedriveLogo from "../../Assets/pipedrive_logo.png";
import NuvemshopLogo from "../../Assets/nuvemshop_logo.png";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaHubspot />} text="Hubspot" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaSalesforce />} text="Salesforce" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<SiZendesk />} text="Zendesk" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<SiDynamics365 />} text="Dynamics 365" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<img src={PipedriveLogo} alt="Pipedrive Logo" style={{ width: "3em", height: "0.8em", verticalAlign: "middle" }} />} text="Pipedrive" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<SiTwilio />} text="Twilio" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<img src={GenesysLogo} alt="Genesys Logo" style={{ width: "1em", height: "0.8em", verticalAlign: "middle" }} />} text="Genesys" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<img src={NuvemshopLogo} alt="Nuvemshop Logo" style={{ width: "3em", height: "0.5em", verticalAlign: "middle" }} />} text="Nuvemshop" />
      </Col>
    </Row>
  );
}

function IconWithTooltip({ icon, text }) {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);

  return (
    <>
      <div ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {icon}
      </div>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

export default Toolstack;
