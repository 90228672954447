import React from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";


function ResumeNew() {

  return (
    <div>
<iframe title="Google Forms" src="https://docs.google.com/forms/d/1zyl7b5tq2lfV9kvjAPKhbO13knZnsWMhXYxaSiPZ_WU/viewform?edit_requested=true" width="730" height="800" style={{ marginTop: '100px' }}></iframe>



    </div>
  );
}

export default ResumeNew;
