import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Unindo forças e talentos, <span className="purple">Amanda</span> e{" "}
            <span className="purple">Bruno </span>
            são os pilares por trás da Omni Engaje.
            <p />
            Bruno, com sua longa experiência em{" "}
            <span className="purple">atendimento ao cliente</span>, um
            conhecimento sólido em{" "}
            <span className="purple">tecnologias de telecomunicações</span> e
            domínio em
            <span className="purple"> integrações de sistemas e APIs</span>,
            aliado a Amanda, cuja expertise na{" "}
            <span className="purple">estruturação de funis de vendas</span> e
            conhecimento avançado em{" "}
            <span className="purple">relações comerciais</span> refletem sua
            expressiva <span className="purple">trajetória no setor</span>.
            Juntos, formam a espinha dorsal de uma empresa comprometida com a
            entrega de soluções inovadoras e eficientes, alinhadas para superar
            os desafios de um ambiente de negócios em rápida evolução.
            <p />
            Nossa experiência não se limita a teorias; é forjada na prática,
            através da{" "}
            <span className="purple">
              participação em projetos ambiciosos com empresas de expressividade
              no mercado
            </span>
            . Esse histórico nos permite oferecer uma perspectiva diferenciada e
            uma abordagem pragmática para superar desafios e aproveitar
            oportunidades.
            <p />
            Com a Omni Engaje, empreendedores visionários encontram não apenas
            soluções, mas um{" "}
            <span className="purple">
              aliado estratégico comprometido com o crescimento e a inovação
              contínua
            </span>
            .
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
