import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              QUEM <span className="purple"> SOMOS </span>?
            </h1>

            <p className="home-about-body">
              Nossa missão é nivelar o campo de atuação no mundo dos negócios,
              democratizando o acesso ao conhecimento, estratégias e métodos
              avançados que tradicionalmente beneficiam apenas grandes
              corporações.
              <p />
              Estamos comprometidos a{" "}
              <b className="purple">capacitar empresas e empreendedores</b>{" "}
              fornecendo-lhes as ferramentas, conhecimentos e suporte
              necessários para construir sistemas eficazes de captação e
              fidelização de clientes.
              <p />
              Através de
              <b className="purple">
                {" "}
                consultoria personalizada, implementação de processos inovadores
                e automação inteligente
              </b>
              , aspiramos transformar o potencial de nossos clientes em sucesso
              tangível, contribuindo para um cenário de negócios mais dinâmico, 
              competitivo e lucrativo.
              <p />
              Nosso objetivo não é apenas ajudar os empreendedores a prosperar, 
              mas também inspirar uma nova geração de negócios a operar de maneira
               mais eficiente, responsável e com <b className="purple">foco na inovação 
               e na excelência operacional</b>.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social"></Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
