import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Alcance resultados excepcionais com uma estratégia de vendas estruturada.",
          "Conquiste clientes 24/7 com nossas <br/> soluções de automação.",
          "Soluções inovadoras para vendas <br/> escaláveis e eficazes.",
          "Potencialize suas vendas e <br/>atendimento ao cliente com<br/> chatbots e IA.",
          "Crie uma experiência de autosserviço <br/> excepcional para seus clientes."
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 10,
        delay: 40,
        html: true
      }}
    />
  );
}

export default Type;
