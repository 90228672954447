import React from "react";
import { Col, Row, Tooltip, Overlay } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import { FaWhatsapp } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaSms } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaWhatsapp />} text="WhatsApp" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaPhoneAlt />} text="Telefone" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<MdOutlineEmail />} text="Email" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaFacebookF />} text="Facebook" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaSms />} text="SMS" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaInstagram />} text="Instagram" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <IconWithTooltip icon={<FaTelegram />} text="Telegram" />
      </Col>
    </Row>
  );
}

function IconWithTooltip({ icon, text }) {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);

  return (
    <>
      <div ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
        {icon}
      </div>
      <Overlay target={target.current} show={show} placement="top">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

export default Techstack;
