import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import vendas2 from "../../Assets/Projects/vendas2.png";
import vendas from "../../Assets/Projects/vendas.png";
import integracao from "../../Assets/Projects/integração.png";
import chatbot from "../../Assets/Projects/chatbot.png";
import customer from "../../Assets/Projects/customer.png";

import Toolstack from "./Toolstack";
import Techstack from "./Techstack";
function Projects() {
  return (
    <Container fluid className="about-section">
      <Container>
        <h1 className="project-heading">
          Serviços <strong className="purple">Sob Medida</strong> Para Você
        </h1>
        <p style={{ color: "white" }}>Veja como podemos te ajudar</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vendas}
              isBlog={false}
              title="Estratégia de Vendas"
              description={[
                "Criação de planos de vendas personalizados baseados na análise do mercado e dos objetivos do cliente.",
                "",
                "Programas de treinamento para equipes de vendas, focados em técnicas de vendas consultivas e habilidades de comunicação.",
              ].map((item, index, array) => (
                <span key={index}>
                  {item}
                  {index < array.length - 1 && <br />}
                </span>
              ))}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={integracao}
              isBlog={false}
              title="Integrações e Comunicação"
              description="Especializados em integrar plataformas, APIs e canais de comunicação para melhorar sua interação com clientes. Nossa customização une suas ferramentas e sistemas, assegurando um fluxo contínuo de informações e uma experiência de atendimento ao cliente unificada e eficiente. Facilitamos interações inteligentes, antecipando as necessidades dos clientes para elevar a satisfação e fidelização através de soluções integradas."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatbot}
              isBlog={false}
              title="Chatbots e URAs"
              description="Otimize seus canais de atendimento e prospecção com URAs e chatbots personalizados, garantindo uma comunicação fluida e eficiente 24/7. Nossa tecnologia avançada permite que você ofereça experiências de atendimento excepcionais, adaptadas às necessidades específicas de seus clientes, enquanto maximiza a eficiência operacional e reduz custos. Transforme a interação com seu público, proporcionando respostas rápidas, precisas e personalizadas em todos os pontos de contato."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vendas2}
              isBlog={false}
              title="Automação de Vendas"
              description="Implementação e configuração de sistemas CRM e ferramentas de automação, projetados para refinamento e eficácia no processo de vendas, além de uma gestão de relacionamento com o cliente excepcionalmente aprimorada. Através de uma abordagem personalizada, buscamos não apenas simplificar as operações de vendas, mas também fortalecer os laços com os clientes, proporcionando interações significativas e uma experiência enriquecedora que destaca o valor que colocamos em cada relação cliente-empresa."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={customer}
              isBlog={false}
              title="Sucesso do Cliente"
              description="Desenvolvimento de programas para aumentar a retenção de clientes e maximizar o valor ao longo do tempo.
              "
            />
          </Col>

        </Row>
      </Container>

      <h1 className="project-heading">
        Engaje por <strong className="purple">qualquer</strong> canal
      </h1>

      <Techstack />

      <h1 className="project-heading">
        Com as <strong className="purple">ferramentas certas</strong> para seu negócio
      </h1>
      <Toolstack />
      <Particle />
    </Container>
  );
}

export default Projects;
